import React from 'react';
import './YoutubeVideo.css'

const YouTubeVideo = ({ videoId }) => (
  <div className="video-container">
    <div className="video-responsive">
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  </div>
);

export default YouTubeVideo;
