import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Card, Paper } from '@mui/material';
import axios from 'axios'
// components
import './beranda.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useState, useEffect } from 'react';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import { useNavigate } from 'react-router-dom';
// import { AppKudapanTerbaru } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function MakananKhas() {
  // const theme = useTheme();
  const API_URL = process.env.REACT_APP_API
  const BACKEND_API = process.env.REACT_APP_BE
  const navigate = useNavigate();

  const handleCardClick = (id) => {
    // Navigate to a specific page or perform an action when the card is clicked
    // window.location.href = `${id}`;
    navigate(`${id}`)

  };
  // const [images, setImages] = useState([])
  // eslint-disable-next-line
  let images = []
  


  const [kudapanTerbaru, setKudapanTerbaru] = useState([])

  const getKudapanTerbaru = async () => {
    try {
      await axios.get(`${API_URL}join/allKudapan`)
      .then(({data}) => {
        setKudapanTerbaru(data?.data)
      })
      .catch((err) =>
      {
        if(err.response.status === 404){
          setKudapanTerbaru([])
        }
    })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
   
    getKudapanTerbaru();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
   
    <div style={{backgroundColor: "white"}} >
      <Helmet>
      <title> {localStorage.getItem("judul") !== null ? localStorage.getItem("judul") : ''} | Makanan Khas </title>
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={window.location.href} />
      <meta name="description" content="Gastronomi Kuliner Bandung Barat" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <Container maxWidth="lg">
        <Typography className='text1'>
          Makanan Khas.
        </Typography>
        <Grid container spacing={2} >
          <Grid item xs={12} md={12} lg={12}>

            <Grid container spacing={2} style={{textAlign:'center'}}>

            {
                kudapanTerbaru?.map((data, i) => (
                  
                  <Grid item xs={12} sm={4} md={4} lg={4} key={i}>
                  <Card
                    className="kudapan"
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                    
                      overflow: 'hidden',
                    }}
                    onClick={() => handleCardClick(`/detail/${data?.id}`)}
                  >
                    <img
                      src={`${BACKEND_API}${data?.image1}`}
                      alt={i}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        display: 'block',
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <Paper style={{ backgroundColor: 'rgba(0, 0, 0, 0.0)' }}>
                        <div style={{ display: 'block' }}>
                          <RestaurantOutlinedIcon style={{ color: 'white', fontSize: 40, fontWeight: 'bold' }} />
                        </div>
                        <h2 style={{ color: 'white', padding: 15 }}>{data?.nama_makanan}</h2>
                      </Paper>
                    </div>
                  </Card>
                </Grid>

                ))
              }
              
            </Grid>

          </Grid>
        
         


        
        </Grid>
      </Container>
    </div>
  );
}
